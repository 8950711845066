<template>
  <div>
    <stripe-checkout
      v-if="payNow"
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :session-id="backendData ? sessionId : ''"
    />
    <div v-if="noCertificateType">
      <div class="row  justify-content-center">
        <!--        ////-->
        <!--        <div class="col">
          <div class="mb-2">
            Number of CPS
          </div>
          <a-select
            :default-value="10"
            style="width: 100%"
            @change="handleChangeNumber"
          >
            <a-select-option
              v-for="item in numbers_spc"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </div>-->
        <!--        ////-->
        <!--        <div class="col">-->
        <!--          <div class="mb-2">Request Per Second</div>-->
        <!--          <a-select :default-value="10" style="width: 100%" @change="handleChangeNumber">-->
        <!--            <a-select-option :value="item.value" v-for="item in per_seconds" :key="item.value">-->
        <!--              {{ item.text }}-->
        <!--            </a-select-option>-->
        <!--          </a-select>-->
        <!--        </div>-->
        <div class=" col-md-3 ">
          <div class="p-2 flex-fill bd-highlight">
            Duration of Service
          </div>
          <a-select
            :default-value="10"
            style="width: 100%"
            @change="handleChangeDuration"
          >
            <template
              v-for="item in duration_list"
              :key="item.value"
            >
              <a-select-option :value="item.value">
                {{ item.text }}
              </a-select-option>
            </template>
          </a-select>
        </div>
      </div>
      <div class="row  justify-content-center">
        <div class="mt-4 d-flex flex-column">
          <div class="font-size-32">
            Total: ${{ total }}
          </div>
          <button
            class="btn btn-success mt-2"
            @click="submit"
          >
            Pay Now
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import axios from "@/axios";
export default {
  components: {
    StripeCheckout,
  },
  data() {
    return {
      clickedSubmit: false,
      backendData: null,
      sessionId: '',
      step: 4,
      number: 10,
      duration: 10,
      loading: false,
      payNow: false,
      numbers_spc: [
        {
          value: 10,
          text: '10 number',
        },
        {
          value: 20,
          text: '20 numbers',
        },
        {
          value: 30,
          text: '30 numbers',
        },
        {
          value: 40,
          text: '40 numbers',
        },
      ],
      per_seconds: [
        {
          value: 10,
          text: '10 seconds',
        },
        {
          value: 20,
          text: '20 seconds',
        },
        {
          value: 30,
          text: '30 seconds',
        },
        {
          value: 40,
          text: '40 seconds',
        },
      ],
      duration_list: [
        {
          value: 10,
          text: '10 days',
        },
        {
          value: 30,
          text: '1 month',
        },
        {
          value: 45,
          text: '1.5 months',
        },
        {
          value: 60,
          text: '2 months',
        },
      ],
      cert_product: 'price_1IfXe9IUTZwmpTEPx0P2Kou0',
      successURL: null,
      cancelURL: null,
      lineItems: [
        {
          price: 'price_1IfXe9IUTZwmpTEPx0P2Kou0',
          quantity: 1,
        },
      ],
    }
  },
  computed: {
    type() {
      return this.$route.params.type
    },
    noCertificateType() {
      return this.type !== 'cert'
    },
    total() {
      return (this.duration + this.number) * this.step
    },
    mode() {
      return this.$route.params.mode
    },
    url() {
      return window.location.protocol + '//' + window.location.host
    },
    publishableKey() {
      return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    },
  },
  created() {
    this.pk = process.env.VUE_APP_PK

    this.cancelURL = window.location.URL
    this.successURL = this.url + '/dashboard'
  },
  mounted() {
    this.createPayment()
    if (!this.noCertificateType) {
      this.successURL = this.url + '/create/certificate'
    }
  },
  methods: {
    createPayment() {
      axios
              .post(`/payments/create_payment`,{
                'price': this.total
              })
              .then(res => {
                this.backendData = res.data
                this.sessionId = this.backendData.id
              })
              .catch((err) => {
                console.log(err)
              })
    },
    aga() {
      console.log('aga')
    },
    handleChangeDuration(e) {
      this.duration = e
    },
    handleChangeNumber(e) {
      this.number = e
    },
    submit() {
      this.payNow = true
      this.createPayment()
      setTimeout(() => {
        this.$refs.checkoutRef.redirectToCheckout()
      }, 50)
    },
  },
}
</script>
